import React from 'react';
import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout pathName="404">
    <div className="container">
      <h1>404 NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
